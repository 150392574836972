<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
				@submit.native.prevent>
				<el-form-item label="类型:" label-width="47px" class="w-170">
					<el-select v-model="table.where.type" clearable>
						<el-option value="10" label="全部"></el-option>
						<el-option value="4" label="跑腿"></el-option>
						<el-option value="3" label="超市"></el-option>
						<el-option value="5" label="叫餐"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="姓名:" label-width="50px" class="w-200">
					<el-input v-model="table.where.name" placeholder="" clearable />
				</el-form-item>
				<el-form-item label="手机号:" label-width="62px" class="w-200">
					<el-input v-model="table.where.phone" placeholder="" clearable />
				</el-form-item>
				<el-form-item label="省:" label-width="35px" class="w-170">
					<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid),$forceUpdate()" placeholder='请选择省' clearable>
						<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市:" label-width="35px" class="w-150">
					<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid),$forceUpdate()"
						placeholder='请选择市' clearable>
						<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
					<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
						<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
							:label="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
						class="ele-btn-icon">搜索</el-button>
					<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
				</el-form-item>
			</el-form>
			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row :stripe=true>
				<template slot-scope="{index}">
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="type_name" label="类型" show-overflow-tooltip min-width="80">
					</el-table-column>
					<el-table-column prop="name" label="店铺名称" show-overflow-tooltip min-width="140">
						</el-table-column>
						<el-table-column prop="envelope_money" label="本月红包收益" show-overflow-tooltip min-width="120" />
					<el-table-column prop="invite_money" label="本月推广收益" show-overflow-tooltip min-width="120" />
					<el-table-column prop="invite_num" label="累推广数量" show-overflow-tooltip min-width="220" />
					<el-table-column prop="money" label="累计收益" show-overflow-tooltip min-width="160" />
					<el-table-column prop="month_cancel" label="当月取消的订单" show-overflow-tooltip min-width="140" />
					<el-table-column prop="month_order_price" label="本月总收益" show-overflow-tooltip min-width="100" />
					<el-table-column prop="phone" label="手机号" show-overflow-tooltip min-width="140" />
					<el-table-column prop="score" label="评分" show-overflow-tooltip min-width="100" />
					<el-table-column prop="today_cancel" label="当日取消的订单" show-overflow-tooltip min-width="100" />
					<el-table-column label="操作" min-width="220px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link @click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false"
								v-if="permission.includes('sys:runcheck:view')">查看详情</el-link>
							<el-link @click="handlecheckSuccess(row)" icon="el-icon-success" type="primary"
								:underline="false" v-if="permission.includes('sys:runcheck:driver_success') && row.status == 2">通过</el-link>
							<el-link @click="handleadopt(row)" icon="el-icon-error" type="danger" :underline="false"
								v-if="permission.includes('sys:runcheck:driver_error') && row.status == 2">驳回</el-link>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 通过 -->
		<el-dialog v-dialogDrag title="审核确认" :visible.sync="dialogVisiblePlans" width="1010px" :destroy-on-close="true" :lock-scroll="false">
			<el-form :model="checkReason" ref="checkReason" @submit.native.prevent :rules="editRules">
				<el-form-item label="分润比例:" label-width="120px" prop="charge">
					<el-input v-model="checkReason.charge" placeholder="请填写分润比例(%)" style="width: 180px;" /> %
				</el-form-item>
			</el-form>
			<div style="height: 60px;margin-left: 46px;line-height: 60px;font-size: 16px;">签名处:</div>
			<!-- 签名 -->
			<vue-esign id='canvas' ref="esign" :width='800' :height="500" style="border: 1px dashed #c2c1c1; margin: 0 auto;"
				:isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" />
			<div slot="footer">
				<el-button @click="handleReset()" type="primary" size="small">清空</el-button>
				<el-button @click="dialogVisiblePlans=false">取消</el-button>
				<el-button type="primary" @click="handleGenerate()">通过</el-button>
			</div>
		</el-dialog>
		<!-- 确认通过 -->
		<el-dialog v-dialogDrag title="审核确认" :visible.sync="dialogVisiblePlansAgain" width="400px" :destroy-on-close="true" :lock-scroll="false">
			<el-form :model="checkReason" @submit.native.prevent>
				<el-form-item label="确认通过该审核吗？" label-width="160px">
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button @click="dialogVisiblePlansAgain=false">取消</el-button>
				<el-button type="primary" @click="handlereject()">确认</el-button>
			</div>
		</el-dialog>
		<!-- 查看详情 -->
		<el-dialog v-dialogDrag title="查看商户详情" :visible.sync="dialogTableVisibleCheck" width="850px" :destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm" label-width="200px">
					<div style="display: flex;">
						<div style="padding-right: 50px;border-right: 1px solid #c6c6c6;">
							<el-form-item label="今日取消订单量：" prop="money">
								{{checkForm.today_cancel}}
							</el-form-item>
							<el-form-item label="本月累计取消订单量：" prop="invite_money">
								{{checkForm.thisMon_cancel}}
							</el-form-item>
							<el-form-item label="本月累计接单量：" prop="total_time">
								{{checkForm.month_order_total}}
							</el-form-item>
							<el-form-item label="本月总收益：" prop="money">
								{{checkForm.month_order_price}}
							</el-form-item>
							<el-form-item label="本月订单收益：" prop="invite_money">
								{{checkForm.month_order_earning}}
							</el-form-item>
							<el-form-item label="本月红包收益：" prop="total_time">
								{{checkForm.month_order_fee}}
							</el-form-item>
							<el-form-item label="本月推广收益：" prop="total_time">
								{{checkForm.month_order_promote}}
							</el-form-item>
							<el-form-item label="商家姓名：" prop="true_name">
								{{checkForm.name}}
							</el-form-item>
							<el-form-item label="头像：">
								<el-image v-if="checkForm.true_head" style="width:70px;height:70px;" :src="checkForm.true_head"
									fit="cover" :preview-src-list="[checkForm.true_head]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<!-- <el-form-item label="是否载客：" prop="phone" v-if="checkForm.type==4">
								{{checkForm.is_carry}}
							</el-form-item> -->
							<el-form-item label="手机号：" prop="phone">
								{{checkForm.phone}}
							</el-form-item>
							<el-form-item label="所属区域：">
								{{checkForm.driver_area}}
							</el-form-item>
							<el-form-item label="累计接单收益：" prop="money">
								{{checkForm.money}}
							</el-form-item>
							<el-form-item label="诚信度：" prop="invite_money">
								{{checkForm.credit}}
							</el-form-item>
							<el-form-item label="累计在线时长：" prop="total_time">
								{{checkForm.total_time}} 分钟
							</el-form-item>
							<el-form-item label="最近的登录时间：" prop="money">
								{{checkForm.active_time*1000 | toDateString }}
							</el-form-item>
						</div>
						<div>
							<el-form-item label="注册时间：" prop="invite_money">
								{{checkForm.create_time*1000 | toDateString }}
							</el-form-item>
							<el-form-item label="性别：" prop="total_time">
								{{checkForm.sex==0?'男':'女'}}
							</el-form-item>
							<el-form-item label="真实姓名：" prop="total_time">
								{{checkForm.true_name}}
							</el-form-item>
							<el-form-item label="身份证号：" prop="idcard">
								{{checkForm.idcard}}
							</el-form-item>
							<el-form-item label="支付宝账号：" prop="idcard">
								{{checkForm.ali_number?checkForm.ali_number:'无'}}
							</el-form-item>
							<!-- <el-form-item label="车牌号：" prop="car_number" v-if="checkForm.type == 4">
								{{checkForm.car_number}}
							</el-form-item> -->
							<el-form-item label="手持身份证照片：">
								<el-image v-if="checkForm.idcard_hand" style="width:70px;height:70px;" :src="checkForm.idcard_hand"
									fit="cover" :preview-src-list="[checkForm.idcard_hand]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="身份证正面照：">
								<el-image v-if="checkForm.idcard_front" style="width:70px;height:70px;" :src="checkForm.idcard_front"
									fit="cover" :preview-src-list="[checkForm.idcard_front]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="身份证反面照：">
								<el-image v-if="checkForm.idcard_back" style="width:70px;height:70px;" :src="checkForm.idcard_back"
									fit="cover" :preview-src-list="[checkForm.idcard_back]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<!-- <el-form-item label="驾驶证正面照：">
								<el-image v-if="checkForm.jiacard_front" style="width:70px;height:70px;" :src="checkForm.jiacard_front"
									fit="cover" :preview-src-list="[checkForm.jiacard_front]"></el-image>
								<span v-else>无</span>
							</el-form-item> -->
							<el-form-item label="营业执照：" v-if="checkForm.type == 3 || checkForm.type == 5">
								<el-image v-if="checkForm.business_license" style="width:70px;height:70px;" :src="checkForm.business_license"
									fit="cover" :preview-src-list="[checkForm.business_license]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="食品安全许可证：" v-if="checkForm.type == 5">
								<el-image v-if="checkForm.food_safety" style="width:70px;height:70px;" :src="checkForm.food_safety"
									fit="cover" :preview-src-list="[checkForm.food_safety]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="公司法人身份证正面：" v-if="checkForm.type == 4">
								<el-image v-if="checkForm.company_legal_idcard_font" style="width:70px;height:70px;" :src="checkForm.company_legal_idcard_font"
									fit="cover" :preview-src-list="[checkForm.company_legal_idcard_font]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="公司法人身份证反面：" v-if="checkForm.type == 4">
								<el-image v-if="checkForm.company_legal_idcard_back" style="width:70px;height:70px;" :src="checkForm.company_legal_idcard_back"
									fit="cover" :preview-src-list="[checkForm.company_legal_idcard_back]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="状态：" prop="orders">
								{{checkForm.status==1?'审核通过':(checkForm.status==2?'审核中':'已驳回')}}
							</el-form-item>
						</div>
					</div>
				</el-form>
			</el-card>
		</el-dialog>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";
	// import {baseUrl} from '@/utils/env.js';
	export default {
		name: "SysUser",
		data() {
			return {
				table: {url: '/Errand/listErrandCheck',where: {}}, // 表格配置
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单弹窗
				editForm: {}, // 表单数据
				editRules: { // 表单验证规则
					charge: [{
						required: true,
						message: '请输入分润比例',
						trigger: 'blur'
					}],
				},
				provArr: [],
				cityArr: [],
				districtArr: [],
				checkForm: [],
				dialogTableVisibleCheck: false,
				canvasTxt: null,
				stage_info: [],
				isDown: false,
				lineWidth: 6,
				lineColor: '#000000',
				bgColor: '',
				resultImg: '',
				isCrop: false,
				dialogVisiblePlans: false,
				dialogVisiblePlansAgain:false,
				checkReason: {},
				signature:{}
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})
		},
		computed: {
			...mapGetters(["permission"]),
		},
		mounted() {},
		methods: {
			// 查看用户信息
			handlecheck(row) {
				this.dialogTableVisibleCheck = true
				this.checkForm = row;
			},
			handlecheckSuccess(row) {
				this.dialogVisiblePlansAgain = true
				this.checkReason = row;
			},
			// 再次确认
			handlereject() {
				const loading = this.$loading({lock: true});
				//console.log(this.checkReason)
				this.$http.post('/Errand/editErrandSuccess', {
					id: this.checkReason.id,
					service_charge: this.checkReason.charge,
					m_sign:this.checkReason.signature
				}).then(res => {
					loading.close();
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table.reload();
						this.dialogVisiblePlansAgain = false
						this.dialogVisiblePlans=false
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			},
			// 驳回
			handleadopt(row) {
				this.$confirm('确认驳回该审核？', '提示', {type: 'warning'}).then(() => {
					const loading = this.$loading({lock: true});
					this.$http.post('/Errand/editErrandError', {id: row.id}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			// 确认
			handleGenerate() { // 获取base64
				this.$refs['checkReason'].validate((valid) => {
					if (valid) {
						var _this = this
						_this.$refs.esign.generate().then(res => {
							// 转成文件
							// var blob = _this.dataURLtoBlob(res)
							// var tofile = _this.blobToFile(blob, '签名.jpg')
							this.$http.post('/Common/uploadBase64', {
								base64_image_content: res
							}).then(res => {
								if (res.data.code === 0) {
									this.checkReason.signature = res.data.data.src
									this.dialogVisiblePlansAgain = true
								} else {
									this.$message.error(res.data.msg);
								}
							}).catch(err => {
								//console.log('fail:' + err);
							})
							//
							// setTimeout(async () => {
							//   const formData = new FormData()
							//   formData.append('file', tofile, tofile.name)
							//   formData.append('fileType', 9)
							//   //uploadImage
							//   // ajax 请求
							// })
						}).catch(e => {
							// // 画布没有签字时提示
							// this.$notify({
							//   title: '提示',
							//   message: '失败',
							//   type: 'warning'
							// });
							this.$message.error("请在下方签名");
						})
					} else {
						return false;
					}
				});
			},
			// 将base64转换为blob
			dataURLtoBlob(dataurl) {
				var arr = dataurl.split(',')
				var mime = arr[0].match(/:(.*?);/)[1]
				var bstr = atob(arr[1])
				var n = bstr.length
				var u8arr = new Uint8Array(n)
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n)
				}
				return new Blob([u8arr], {
					type: mime
				})
			},
			// 将blob转换为file
			blobToFile(theBlob, fileName) {
				theBlob.lastModifiedDate = new Date()
				theBlob.name = fileName
				return theBlob
			},
			// 清除
			handleReset() {
				if (this.$refs.esign) {
					this.$refs.esign.reset();
				}
				var obj = document.getElementById("canvas");
				obj.style.backgroundColor = "#fff"; //保存时的背景颜色
			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			exportOrderParams() {
				const query = this.table.where
				const selectionIds = this.choose.map(item => {
					return item.id
				})
				return {
					ids: selectionIds,
					...query
				}
			},
			/* 导出数据Excel */
			exportExcel() {
				let info = JSON.parse(JSON.stringify(this.table.where));
				//console.log('user data export')
				this.$http.get("/driver/exportExcelForCheck", info).then((res) => {
					let data = res.data;
					if (data.code == 0) {
						// 下载文件
						window.location.href = data.data;
						this.$message({
							type: "success",
							message: "导出成功",
						});
					} else {
						this.$message.error("导出失败");
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ele-block .el-upload-dragger {
		width: 100%;
	}
	/deep/.el-dialog {
		margin-top: 80px !important;
	}
</style>
